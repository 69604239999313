"use client";

import { changeClientSideLocale, useTranslation } from "@/lib/i18n";
import { Lng, LngMap } from "@/lib/i18n/settings";
import { Globe } from "lucide-react";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

type LanguageSelectorProps = {
  className?: string;
  hideLabel?: boolean;
};

const LanguageSelector = ({ className, hideLabel }: LanguageSelectorProps) => {
  const { t: dashboardT, i18n } = useTranslation({ ns: "dashboard-settings" });

  const [currentLng, setCurrentLng] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  const handleLngOnChange = (lng: string) => {
    setCurrentLng(lng);

    changeClientSideLocale(lng as Lng);
  };

  return (
    <div className={className}>
      {!hideLabel && (
        <Label className="mb-2 pb-2 pl-1">
          {dashboardT("menu.general.lang")}
        </Label>
      )}
      <Select
        value={currentLng}
        onValueChange={(value) => {
          handleLngOnChange(value);
        }}
      >
        <SelectTrigger>
          <div className="flex items-center gap-2">
            <Globe size={16} className="text-neutral-600" />
            <SelectValue />
          </div>
        </SelectTrigger>
        <SelectContent>
          {Object.keys(LngMap).map((lng) => (
            <SelectItem key={lng} value={lng}>
              {LngMap[lng]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default LanguageSelector;
