import { emailOTPClient } from "better-auth/client/plugins";
import { createAuthClient } from "better-auth/react";
import { AppSite } from "./config";

export const authClient = createAuthClient({
  baseURL: AppSite,
  plugins: [
    emailOTPClient(),
    // oneTapClient({
    //   clientId: GoogleClientId,
    // }),
  ],
});

export const { signIn, signOut, useSession } = authClient;
